import React, { useState, useEffect, useContext } from 'react';
import PlusMinusButton from './PlusMinusButton';
import { isMobile } from 'react-device-detect';
import { isTablet } from '../deviceUtils';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ClearIcon from '@mui/icons-material/Clear';
import ProductCardCart from './ProductCardCart';
import Loading from './Loading/Loading';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  getCart,
  getCities,
  getUserInfo,
  checkCoupon,
  createOrderCustomer,
} from '../APIS';

import emptycart from '../assets/images/emptycart.png';

import AnimatedIcon from './AnimatedIcon/AnimatedIcon';

import MyContext from '../contexts/MyContext';

const Card = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [name, setName] = useState('');
  const [cities, setCities] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [shippingPrice, setShippingPrice] = useState(0);
  const [coupon, setCoupon] = useState('');
  const [couponData, setCouponData] = useState('');
  const [total, setTotal] = useState(0);

  const [mobile, setMobile] = useState('');
  const [city, setCity] = useState(0);
  const [address, setAddress] = useState('');

  const {
    productsInCart,
    setProductsInCart,
    productsInWishlist,
    setProductsInWishlist,
  } = useContext(MyContext);
  const navigate = useNavigate();
  const handleCreateOrderButton = async () => {
    const cityObject = cities.filter((item) => item.id == city);

    const order = {
      city: cityObject[0].name,
      address,
      mobile,
      coupon,
      customerName: name,
      cartId: localStorage.getItem('cartId'),
    };
    try {
      const response = await createOrderCustomer(order);
      if (response.status === 'success') {
        toast.success(response.msg);
        localStorage.removeItem('cartId');
        navigate('/');
      } else {
        toast.error(response.msg);
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchCart = async () => {
    const cartId = localStorage.getItem('cartId');
    try {
      const response = await getCart(cartId);

      setProducts(response.data);
      setProductsInCart(response.data.length);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    var t = 0;
    products.forEach((element) => {
      setTotal(t + element.quantity * element.price);
      t = t + element.quantity * element.price;
    });
  }, [products]);

  useEffect(() => {
    localStorage.setItem(
      'breadCrump',
      JSON.stringify([
        { label: 'الرئيسية', path: '/' },
        {
          label: 'سلة المشتريات',
          path: '/cart',
        },
      ])
    );
    window.dispatchEvent(new Event('storage'));
    fetchCart();
  }, []);

  const cardStyle = {
    display: 'grid',
    gridTemplateColumns: '0.5fr 0.7fr 0.2fr 0.2fr', // Adjust the number of columns as needed
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '8px',
    marginTop: '10px',
    // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    direction: 'rtl',
    gridAutoRows: 'auto', // Set each row's height to adjust automatically based on content
    // maxWidth: '1000px',
    marginLeft: 'auto',
    marginRight: 'auto',
  };

  const imageStyle = {
    // backgroundColor: 'yellow',
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    textAlign: 'right',
  };

  const titleStyle = {
    fontSize: '14px',
    marginTop: '10px',
    marginBottom: '10px',
    textAlign: 'right', // Right-align the title
  };

  const textStyle = {
    margin: '5px',
    textAlign: 'right', // Right-align text content
    fontSize: '12px',
    marginTop: -10,
    marginRight: 0,
    color: '#ccc',
    fontWeight: 'bold',
  };

  const buttonStyle = {};

  const buttonContainer = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '4px',
  };

  const button = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: 10,
    height: 10,
    padding: '8px',
    fontSize: '1.2rem',
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: '#f0b216',
  };

  const countText = {
    fontSize: '1rem',
    margin: '4px',
  };

  const fetchUserInfo = async (cities) => {
    try {
      const response = await getUserInfo();
      setLoading(false);
      if (response.status === 'success') {
        // setUser(response.data[0]);
        setName(response.data[0].name);
        setMobile(response.data[0].mobile);

        setCity(response.data[0].city);

        setAddress(response.data[0].address);
      }
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    if (cities.length > 0 && city) {
      const selectedCity = cities.filter((fcity) => fcity.id == city);

      setShippingPrice(selectedCity[0].price);
    }
  }, [city, cities]);
  const getCouponValue = async () => {
    try {
      const response = await checkCoupon(coupon);

      if (response.status === 'success' && response.data.length > 0) {
        setCouponData(response.data[0]);
      } else {
        setDiscount(0);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (couponData) {
      if (couponData.coupontype === 'ثابت') {
        setDiscount(couponData.couponvalue);
      } else if (couponData.coupontype === 'نسبة مئوية') {
        setDiscount(total * (couponData.couponvalue / 100));
      }
    }
  }, [couponData, total]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        setLoading(true);
        const response = await getCities();

        if (response.status === 'success') {
          setCities(response.data);
          fetchUserInfo(response.data);
          setCity(response.data[0].id);
        }
      } catch (error) {
        throw error;
      }
    };

    fetchCities();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div
          style={{
            display: isMobile ? 'block' : 'flex',
            flexDirection: 'row',
            marginLeft: '5px',
            marginRight: '5px',
          }}
        >
          <div
            style={{
              flex: 0.6,
            }}
          >
            <div>
              {products.length > 0 ? (
                products.map((product) => (
                  <ProductCardCart
                    setProducts={setProducts}
                    products={products}
                    key={product.id}
                    product={product}
                    fetchCart={fetchCart}
                  />
                ))
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '300px',
                  }}
                >
                  <img
                    src={emptycart}
                    style={{ width: '100px', height: '100px' }}
                  />
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              flex: 0.4,
              backgroundColor: '#cccccc99',
              borderRadius: '10px',
              margin: 10,
              paddingLeft: 30,
              paddingRight: 30,
              height: '460px',
              position: isMobile ? '' : 'sticky',
              top: '10px',
              marginBottom:150
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
              }}
            >
              <p style={{ fontWeight: 'bold', fontSize: 20 }}>
                معلومات التوصيل
              </p>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row-reverse',
                marginTop: '5px',
              }}
            >
              <p
                style={{
                  fontWeight: 'bold',
                  minWidth: '100px',
                  textAlign: 'right',
                  marginLeft: '10px',
                }}
              >
                الاسم
              </p>
              <input
                style={{
                  borderRadius: '10px',
                  border: 'none',
                  flex: 1,
                  textAlign: 'right',
                  fontWeight: 'bold',
                  paddingRight: '10px',
                  outline: 'none',
                }}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row-reverse',
                marginTop: '5px',
              }}
            >
              <p
                style={{
                  fontWeight: 'bold',
                  minWidth: '100px',
                  textAlign: 'right',
                  marginLeft: '10px',
                }}
              >
                رقم الهاتف
              </p>
              <input
                type='tel'
                style={{
                  borderRadius: '10px',
                  border: 'none',
                  flex: 1,
                  textAlign: 'right',
                  fontWeight: 'bold',
                  paddingRight: '10px',
                  outline: 'none',
                }}
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
              />
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row-reverse',
                marginTop: '5px',
              }}
            >
              <p
                style={{
                  fontWeight: 'bold',
                  minWidth: '100px',
                  textAlign: 'right',
                  marginLeft: '10px',
                }}
              >
                المدينة
              </p>

              <select
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}
                style={{
                  borderRadius: '10px',
                  border: 'none',
                  flex: 1,
                  textAlign: 'right',
                  fontWeight: 'bold',
                  paddingRight: '10px',
                  outline: 'none',
                  backgroundColor: '#fff',
                  color: '#000',
                  direction: 'rtl',
                }}
              >
                {cities.map((city) => (
                  <option
                    key={city.id}
                    value={city.id}
                    style={{
                      textAlign: 'right',
                      fontWeight: 'bold',
                      paddingRight: '10px',
                    }}
                  >
                    {city.name}
                  </option>
                ))}
              </select>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row-reverse',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            >
              <p
                style={{
                  fontWeight: 'bold',
                  minWidth: '100px',
                  textAlign: 'right',
                  marginLeft: '10px',
                }}
              >
                العنوان
              </p>
              <input
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
                style={{
                  borderRadius: '10px',
                  border: 'none',
                  flex: 1,
                  textAlign: 'right',
                  fontWeight: 'bold',
                  paddingRight: '10px',
                  outline: 'none',
                }}
              />
            </div>

            <div
              style={{ width: '100%', backgroundColor: 'gray', height: '1px' }}
            ></div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'right',
              }}
            >
              <p style={{ fontWeight: 'bold', fontSize: 20 }}>ملخص الطلبية</p>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
              }}
            >
              <p style={{ fontWeight: 'bold' }}>مجموع الطلبية</p>
              <p>₪ {total} </p>
            </div>
            <div
              style={{
                display: 'flex',
                marginBottom: '5px',
                justifyContent: 'space-between',
                flexDirection: 'row-reverse',
                marginTop: '15px',
                // marginLeft: '1px',
              }}
            >
              <input
                type='text'
                style={{
                  borderRadius: '10px',
                  border: 'none',
                  flex: 1,
                  textAlign: 'right',
                  fontWeight: 'bold',
                  paddingRight: '10px',
                  outline: 'none',
                }}
                placeholder='الكوبون'
                value={coupon}
                onChange={(e) => {
                  setCoupon(e.target.value);
                }}
              />
              <div
                onClick={() => {
                  getCouponValue(coupon);
                }}
                style={{
                  borderRadius: '5px',
                  backgroundColor: '#fcc318',
                  fontWeight: 'bold',
                  marginRight: '10px',
                  marginLeft: '-10px',
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  cursor: 'pointer',
                  fontSize: 13,
                  fontWeight: 'bold',
                }}
              >
                تفعيل
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
              }}
            >
              <p style={{ fontWeight: 'bold' }}>الخصم</p>
              <p>₪ {discount} </p>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
              }}
            >
              <p style={{ fontWeight: 'bold' }}>التوصيل</p>
              <p>₪ {shippingPrice} </p>
            </div>
            <div
              style={{ width: '100%', backgroundColor: 'gray', height: '1px' }}
            ></div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
              }}
            >
              <p style={{ fontWeight: 'bold' }}>المجموع الكلي</p>
              <p style={{ fontWeight: 'bold' }}>
                ₪ {total - discount + shippingPrice}{' '}
              </p>
            </div>
            <div
              onClick={() => {
                if (products.length > 0) {
                  handleCreateOrderButton();
                }
              }}
              style={{
                borderRadius: '5px',
                backgroundColor: products.length > 0 ? '#fcc318' : '#fcc31899',
                fontWeight: 'bold',
                marginRight: '10px',
                paddingLeft: '10px',
                marginTop: '10px',
                marginBottom: '10px',
                paddingRight: '10px',
                cursor: products.length > 0 ? 'pointer' : 'not-allowed',
                fontSize: 18,
                fontWeight: 'bold',
              }}
            >
              تثبيت الطلبية
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Card;
